import { Container, Grid } from "@material-ui/core";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";

const Feature = props => {
  fontawesome.library.add(faLongArrowAltLeft, faLongArrowAltRight);
  const size = props.expanded ? 12 : 4;
  const displayText = props.expanded ? props.longText : props.shortText;
  return (
    <Grid item xs={size}>
      <div className="feature">
        <Container>
          <div className="top-image">
            <img src={props.image} alt="Feature" />
          </div>
          <div className="title">{props.title}</div>
          <div className="text">{displayText}</div>
          {props.expanded ? (
            <div
              className="expand-collapse"
              onClick={() => props.handleExpandCollapse("collapse", props.id)}
            >
              <p>
                Show Less <FontAwesomeIcon icon="long-arrow-alt-left" />
              </p>
            </div>
          ) : (
            <div
              className="expand-collapse"
              onClick={() => props.handleExpandCollapse("expand", props.id)}
            >
              <p>
                Learn More <FontAwesomeIcon icon="long-arrow-alt-right" />
              </p>
            </div>
          )}
        </Container>
      </div>
    </Grid>
  );
};

export default Feature;
