const Util = (function () {
  const isValidEmail = email => {
    const mailformat = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!mailformat.test(email)) {
      return false;
    }
    return true;
  };

  return { isValidEmail: isValidEmail };
})();

export default Util;
