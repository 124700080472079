import axios from "axios";
import Constants from "./Constants";

const AjaxCall = (function () {
  const baseURL = Constants.baseURL;
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.create();

  const submitDetails = async request => {
    return await axios({
      method: "post",
      url: `/submitDetails`,
      data: request,
    });
  };

  const submitWhitepaperEmail = async request => {
    return await axios({
      method: "post",
      url: `/whitepaper`,
      data: request,
    });
  };

  return {
    submitDetails: submitDetails,
    submitWhitepaperEmail: submitWhitepaperEmail,
  };
})();

export default AjaxCall;
