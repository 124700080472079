import "./Values.scss";
import values from "../../Assets/Images/values.png";
import Value from "./Value";
import { Container } from "@material-ui/core";

const Values = (props) => {
  const content = [
    {
      heading: "Encrypt with “Zero Liability”, using ZeroTrust++",
      text: [
        "Bayun relinquishes the service-provider from liability because none of your employees have access to any sensitive customer data. If you don’t have access to customer data, you are not liable during any breaches, as customers themselves have full control over access to their data even if it has been stolen. Note that your company can still choose to keep limited customer data for analytics, etc., after anonymizing it, such that no sensitive PII information is available to any employee of your company, even though your customers themselves will still stay in full control of all their data that is hosted by your service.",
        "With arbitrary access policies tied into encryption, such that authentication and authorization are now enforced by encryption itself, rather than two separate steps with each requiring trusted software for verification; Bayun enables truly Zero Trust setup, or rather ZeroTrust++.",
        "Bayun protects user data for its entire lifecycle - from creation to consumption, or essentially from cradle to grave, and not just from device to device. Hence it is truly E2EE, or rather E2EE++.",
      ],
    },
    {
      heading: "A user-keyed Bank Vault with full privacy",
      text: [
        "Make your key infrastructure a true bank vault with a personal key deposit box controlled by each customer, making your SaaS solution even more secure and private than an on-prem solution deployed and managed by the customer themselves.",
        "Each of your customers, or rather each employee of your customers, has their own digital version of a bank’s personal safe deposit box with their own key (Bayun’s DigiLockbox). Even a bank manager can’t access this customer safe deposit box without the customer themselves getting involved, let alone a thief or hacker.",
      ],
    },
    {
      heading:
        "Flexible & powerful controls for your enterprise / institutional customers",
      text: [
        "When an enterprise company or an institution is using your application or service, their employees are acting as agents to create / modify / consume data on behalf of their employer, and the real ownership of data belongs to the company (in fact in some scenarios, it could even be downstream customers of that institution who are ultimate owners of the data). Bayun provides the ability to exercise full-featured controls for these enterprise customers, with arbitrary enterprise access policies that can be tied into encryption. These policies can also be updated (only by an existing member of authorized policy) without having to touch the encrypted data itself. This enables the enterprise/institutional customers of your service to have full and exclusive control of their data, with this company data being effectively “loaned” to their employees for access. The company can even revoke access to any employee at any point of time with proper controls. In fact your customer company is the one that controls access to this data even for law enforcement purposes as well (either directly or indirectly), irrespective of where the data is actually sitting.",
        "In the event some employee of an enterprise/institutional customer gets hit by a bus, or say a disgruntled employee departs - without voluntarily handing-over control of company assets; the company can securely transfer departed employee’s data to another employee through proper controls that are cryptographically enforced (once again, without having to trust any server-side code). We believe that such controls are absolutely necessary in an enterprise environment in order to protect the company from these unfortunate events. And this needs to be done in such a manner that it does not become a weak point in the entire chain, which can be exploited by a hacked or malicious admin account through privilege escalation or misuse. However, with end-to-end encryption of data, this kind of backdoor functionality is extremely hard to implement correctly, and most of the other implementations of E2EE do not offer it today. Bayun’s DigiLockbox has this functionality built-in, as it was designed from ground-up with enterprise and institutional use in mind, rather than for consumer use only.",
      ],
    },

    {
      heading: "User Experience that works (with passwordless authentication), even for Crypto Wallets!",
      text: [
        "Bayun’s solution provides a usage model similar to iCloud Keychain - with the best security & privacy built-in, without sacrificing usability. If usability gets sacrificed, either the solution doesn’t see much adoption or it starts getting used in sub-optimal ways for convenience, defeating the whole purpose of adding security and making the solution even more vulnerable. For example, the current security issues being faced in crypto-space across not only the hot wallets (which are supposed to be convenient, though not as secure), but even cold wallets as well (which are supposed to be very secure if used correctly, though the inconvenience still makes them not only unpopular but highly vulnerable, making them arguably even less secure for most people than some of the hot wallets). Given the recent press on crypto accounts being hacked even with 2 and 3 factor authentication, no wonder that the amount of token value being lost is very high. DigiLockbox is the perfect developer-focused solution for this problem, as it lets wallet providers in Crypto/NFT space create a user wallet experience that is more convenient than an online wallet and yet more secure than even a hardware wallet. Considering that DigiLockbox can keep crypto-currency tokens and high-value NFTs safe for individual users as well as institutions (both institutional investors and enterprises), it can easily keep any data assets safe!",
        "Bayun generalizes iCloud Keychain model to work across all use-cases, especially in enterprise environments, where data needs to be accessible by multiple entities (users and/or services), with arbitrary access policies that can be changed without having to touch the data itself. Yet it is practically easy to deploy for application builders, as well as to manage for enterprise companies whose employees are creating/consuming the data. It is also simple to use for all users (developers, enterprises/institutions, employees or consumers), with Keychain like security, privacy and ease of use. It even includes a secure solution for user account recovery for service providers (both unassisted and assisted), which none of the other implementations of E2EE provide today, not even iCloud Keychain itself. So, for example, if a user forgets their iCloud account password or doesn't remember any of their device PINs, they lose access to their keychain data forever. This is not acceptable in many scenarios, e.g. in case of a crypto-currency wallet which could hold a large amount of token-value. Bayun’s solution has a built-in mechanism to enable user account recovery in such scenarios under service-provider control, without it becoming a security loophole in itself for the user or the service-provider. For users of applications themselves, there is little change in user-experience.",
        "By tying together authentication as well as authorization into data encryption itself, and using local device’s secure storage for keeping user’s personal encryption keys protected with biometrics (what-ever is available and configured on the device - fingerprint, face ID, or device PIN), Bayun’s SDK can be used to provide a password less authentication & authorization experience to users. This considerably simplifies the user experience, despite providing far superior security (again similar to Keychain itself). Note that passwordless authentication is a recent phenomenon that tries to simplify the user experience for applications by not using passwords for login/auth. However all these solutions simplify the user experience but still require trust in the underlying authentication servers (as well as authorization logic), and hence do not even come close to the level of security and privacy provided by Bayun’s solution. So effectively, Bayun’s solution not only simplifies the user experience with password less auth by securely using user’s personal key on the device, but also makes it Zero-Trust++ by tying authorization logic into the same key for unlocking the data.",
      ],
    },
    {
      heading: "Instant Application Integration with minimal cost",
      text: [
        "Bayun’s solution creates the easiest possible mechanism that is technically feasible for developers to add built-in security to any application.",
        "No need to build it yourself to simply chase compliance and solve yesterday’s problems. Get ahead of compliance instead and solve tomorrow’s problems today.",
        "Option to start even simpler by integrating with password-less authentication alone, improving the user-experience first with improved authentication security itself, and expand later with deeper integration of encryption to get the best available data authorization security and privacy for you as well as your customers. No other password-less authentication solution provides such an easy upgrade path, or for that matter any upgrade path, towards zero-liability using end-to-end encryption and beyond.",
      ],
    },
  ];

  return (
    <div className="values-container">
      <Container>
        <div className="values">
          <div className="heading">
            <img src={values} alt="Values" />
            <p>Bayun’s Value Proposition</p>
          </div>
          <div className="content">
            {content.map((value, index) => (
              <Value key={index} {...value} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Values;
