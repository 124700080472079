import "./PFHeader.scss";
import Nav from "../../Nav/Nav";

const PFHeader = props => {
  return (
    <div className="pf-header-container">
      <Nav />
    </div>
  );
};

export default PFHeader;
