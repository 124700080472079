import "./LPHeader.scss";
import Nav from "../../Nav/Nav";

const LPHeader = props => {
  return (
    <div className="lp-header-container">
      <Nav />
    </div>
  );
};

export default LPHeader;
