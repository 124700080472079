import "./Footer.scss";
import logo from "./../Assets/Images/logo.png";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

const Footer = props => {
  fontawesome.library.add(
    faFacebookSquare,
    faTwitter,
    faInstagram,
    faCopyright
  );
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="copyright">
          <div className="logo">
            <img src={logo} alt="Bayun" />
          </div>
        </div>
        <div className="social">
          {/* <FontAwesomeIcon icon={["fab", "facebook-square"]} />
          <FontAwesomeIcon icon={["fab", "twitter"]} />
          <FontAwesomeIcon icon={["fab", "instagram"]} /> */}
        </div>
        <div className="contact">
          <div>contact@bayunsystems.com</div>
          <div>+1-650-450-9951</div>
        </div>
      </div>

      <div className="text">
        <p>
          <FontAwesomeIcon icon={["far", "copyright"]} /> 2024 Bayun Systems,
          Inc. | All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
