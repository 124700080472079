import "./Nav.scss";
import { useState } from "react";
import logo from "./../Assets/Images/logo.png";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Nav = (props) => {
  fontawesome.library.add(faChevronDown, faChevronRight);
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };
  return (
    <nav className="top-nav">
      <img src={logo} alt="Bayun Logo" />
      <div className="contact-info">
        <div>contact@bayunsystems.com</div>
        <div>+1-650-450-9951</div>
      </div>
      <div className="right-buttons">
        <div className={isActive ? "closed resource-container" : "open resource-container"}>
          <button onClick={ToggleClass}>
            Resources
            <FontAwesomeIcon
            icon="chevron-down"
            className="icon"
          />
          </button>
          <ul className="resource-list">
            <a href="https://github.com/bayunsystems/BayunSDK" target="_blank" rel="noopener noreferrer" ><li>GitHub</li></a>
            <li className="guide-menu">
              Developer Guide
              <FontAwesomeIcon
              icon="chevron-right"
              className="icon"
            />
              <ul className="resource-sublist">
                <a href="https://bayun.gitbook.io/bayuncoresdk-android" target="_blank" rel="noopener noreferrer"><li>Android</li></a>  
                <a href="https://bayun.gitbook.io/bayuncoresdk-cpp" target="_blank" rel="noopener noreferrer"><li>C++</li></a>
                <a href="https://bayun.gitbook.io/bayuncoresdk-ios" target="_blank" rel="noopener noreferrer"><li>iOS</li></a>
                <a href="https://bayun.gitbook.io/bayuncoresdk-javascript-programming-guide" target="_blank" rel="noopener noreferrer"><li>Javascript</li></a>
                <a href="https://bayun.gitbook.io/bayun-awss3-wrapper-javascript-programming-guide" target="_blank" rel="noopener noreferrer"><li>Javascript AWSS3 Warpper</li></a>
                <a href="https://bayun.gitbook.io/bayun-awss3-wrapper-ios-programming-guide" target="_blank" rel="noopener noreferrer"><li>iOS AWSS3 Warpper</li></a>
                <a href="https://bayun.gitbook.io/bayun-awss3-wrapper-android-programming-guide" target="_blank" rel="noopener noreferrer"><li>Android AWSS3 Warpper</li></a>
              </ul>
            </li>
          </ul>
        </div>
        <a href="https://digilockbox.com/admin" target="_blank" rel="noopener noreferrer"><button>Login</button></a>
      </div>
      {/* <ul>
        <li>Home</li>
        <li>Developer Practice</li>
        <li>Pricing</li>
        <li>Api &amp; Docs</li>
        <li>Contact Us</li>
      </ul>
      <button>Register</button> */}
    </nav>
  );
};

export default Nav;
