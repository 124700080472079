import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from "@material-ui/core";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const Challenge = (props) => {
  fontawesome.library.add(faCaretDown, faCheckCircle);
  return (
    <div className="challenge">
      <Container>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon="caret-down" />}
            aria-controls={props.id + "-content"}
            id={props.id + "-header"}
          >
            <div className="challenge-heading">{props.heading}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="text">
              {props.text.map((point, index) => {
                return (
                  <div className="point" key={index}>
                    <div className="icon">
                      <FontAwesomeIcon icon="check-circle" />
                    </div>
                    <div
                      className="point-text"
                      dangerouslySetInnerHTML={{ __html: point }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div>
  );
};

export default Challenge;
