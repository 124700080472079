import "./ContactUs.scss";
import { useState } from "react";
import { CircularProgress, Container, Grid, Snackbar } from "@material-ui/core";
import contactUsImage from "../../Assets/Images/contact-us.png";
import AjaxCall from "../../Util/AjaxCall";
import Alert from "@material-ui/lab/Alert";
import Util from "../../Util/Util";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = props => {
  fontawesome.library.add(faCheckCircle);

  const [formData, setFormData] = useState({
    emailAddress: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    company: "",
    appAudienceValue: "N/A",
    awsS3Value: "N/A",
    gradeValue: "N/A",
    applicationUsedValue: "N/A",
    message: "",
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(true);
  const [clickedCount, setClickedCount] = useState(1);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(Date.now().toString());

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ open: false, message: "" });
  };

  const changeFormData = e => {
    const successVar = { ...success };
    if (successVar) {
      setSuccess(false);
    }
    const newFormData = { ...formData };
    newFormData[e.target.name] = e.target.value;
    setFormData(newFormData);
  };

  const handleVerify = () => {
    console.log("Verfied");
    setCaptchaVerified(true);
  };

  const submitDetails = async () => {
    console.log(clickedCount);
    setClickedCount(clickedCount+1);
    
    const details = { ...formData };
    if (details.emailAddress === "") {
      setSnackbar({ open: true, message: "Email Cannot Be Empty" });
      return false;
    }
    if (!Util.isValidEmail(details.emailAddress)) {
      setSnackbar({ open: true, message: "Invalid Email Entered" });
      return false;
    }
    if(showRecaptcha && !captchaVerified){
      setSnackbar({ open: true, message: "Please verify captcha" });
      return false;
    }
    setLoading(true);
    await AjaxCall.submitDetails(details);
    setLoading(false);
    setSuccess(true);
    setFormData({
      emailAddress: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      company: "",
      appAudienceValue: "N/A",
      awsS3Value: "N/A",
      gradeValue: "N/A",
      applicationUsedValue: "N/A",
      message: "",
    });
    if(clickedCount > 3){
      setShowRecaptcha(true);
      setCaptchaVerified(false);
      setCaptchaKey(Date.now().toString());
    }
  };

  return (
    <div className="contact-us-container" ref={ref => props.assignRef(ref)}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="error"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Container>
        <div className="contact-us">
          <div className="heading">
            <img src={contactUsImage} alt="Contact Us" />
            <p>Contact Us</p>
          </div>
          <div className="content">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <div className="form-input">
                  <label>
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.emailAddress}
                    onChange={changeFormData}
                    name="emailAddress"
                    ref={ref => props.assignEmailRef(ref)}
                  />
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="inner-grid">
                <div className="form-input">
                  <label>First Name</label>
                  <input
                    type="text"
                    value={formData.firstName}
                    onChange={changeFormData}
                    name="firstName"
                  />
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="inner-grid">
                <div className="form-input">
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={formData.lastName}
                    onChange={changeFormData}
                    name="lastName"
                  />
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="inner-grid">
                <div className="form-input">
                  <label>Company</label>
                  <input
                    type="text"
                    value={formData.company}
                    onChange={changeFormData}
                    name="company"
                  />
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="inner-grid">
                <div className="form-input">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    value={formData.phoneNumber}
                    onChange={changeFormData}
                    name="phoneNumber"
                  />
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="stretch">
                <div className="form-input">
                  <label>Product Version Interest</label>
                  <select
                    name="gradeValue"
                    value={formData.gradeValue}
                    onChange={changeFormData}
                  >
                    <option>Enterprise Grade</option>
                    <option>Service Provider Grade</option>
                    <option>Free Developer Grade</option>
                    <option>N/A</option>
                  </select>
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="stretch">
                <div className="form-input">
                  <label>Interested in AWS S3?</label>
                  <select
                    name="awsS3Value"
                    value={formData.awsS3Value}
                    onChange={changeFormData}
                  >
                    <option>Yes</option>
                    <option>No</option>
                    <option>N/A</option>
                  </select>
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="stretch">
                <div className="form-input">
                  <label>Application to be Used by</label>
                  <select
                    name="applicationUsedValue"
                    value={formData.applicationUsedValue}
                    onChange={changeFormData}
                  >
                    <option>Employees</option>
                    <option>Customers</option>
                    <option>Partners</option>
                    <option>Multiple-Parties</option>
                    <option>N/A</option>
                  </select>
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="stretch">
                <div className="form-input">
                  <label>App Audience</label>
                  <select
                    name="appAudienceValue"
                    value={formData.appAudienceValue}
                    onChange={changeFormData}
                  >
                    <option>Enterpirse</option>
                    <option>Consumer</option>
                    <option>Both</option>
                    <option>N/A</option>
                  </select>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-input">
                  <label>Message (if any)</label>
                  <input
                    type="text"
                    name="message"
                    value={formData.message}
                    onChange={changeFormData}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="contact-us-captcha">
            {showRecaptcha && (
             <ReCAPTCHA sitekey="6Ld9pwAVAAAAAOl8gepucwGqsDHGFRmu35bmWZzy" key={captchaKey} onChange={handleVerify} />
            )}
            </div>
            <div className="button">
              {loading ? (
                <>
                  <CircularProgress size={30} className="progress" />
                  <button className="loading">Submit</button>
                </>
              ) : (
                <button onClick={submitDetails} 
                // disabled={!captchaVerified}
                >Submit</button>
              )}
            </div>
            <div className="message">
              {success && (
                <>
                  <div className="icon">
                    <FontAwesomeIcon icon="check-circle" />
                  </div>
                  <div className="text">
                    <span>
                      Thanks for your interest in Bayun, we will reach out to
                      you soon.
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
