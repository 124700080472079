import "./LandingPage.scss";
import React from "react";
import Intro from "./Intro/Intro";
import PrivacyAndSecurity from "./PrivacyAndSecurity/PrivacyAndSecurity";
import Challenges from "./Challenges/Challenges";
import Values from "./Values/Values";
import Overview from "./Overview/Overview";
import Help from "./Help/Help";
import Whitepaper from "./Whitepaper/Whitepaper";
import ContactUs from "./ContactUs/ContactUs";
import LPHeader from "./LPHeader/LPHeader";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.contactUsRef = React.createRef();
    this.whitepaperRef = React.createRef();
    this.contactUsEmailRef = React.createRef();
    this.whitepaperEmailRef = React.createRef();
    this.zeroTrustRef = React.createRef();
  }

  executeContactUsScroll = () => {
    this.contactUsRef.scrollIntoView();
    this.contactUsEmailRef.focus();
  };

  executeZeroTrustScroll = () => {
    this.zeroTrustRef.scrollIntoView();
  };

  executeWhitepaperScroll = () => {
    this.whitepaperRef.scrollIntoView();
    this.whitepaperEmailRef.focus();
  };

  assignZeroTrustRef = (ref) => {
    this.zeroTrustRef = ref;
  };

  assignContactUsRef = (ref) => {
    this.contactUsRef = ref;
  };

  assignWhitepaperRef = (ref) => {
    this.whitepaperRef = ref;
  };

  assignContactUsEmailRef = (ref) => {
    this.contactUsEmailRef = ref;
  };

  assignWhitepaperEmailRef = (ref) => {
    this.whitepaperEmailRef = ref;
  };

  render() {
    return (
      <>
        <LPHeader />
        <Intro
          executeScroll={this.executeContactUsScroll}
          executeZeroTrustScroll={this.executeZeroTrustScroll}
        />
        <Overview />
        <PrivacyAndSecurity
          executeScroll={this.executeWhitepaperScroll}
          assignZeroTrustRef={this.assignZeroTrustRef}
        />
        <Challenges />
        <Values />
        <Help />
        <Whitepaper
          assignRef={this.assignWhitepaperRef}
          assignEmailRef={this.assignWhitepaperEmailRef}
        />
        {/* <Blogs /> */}
        <ContactUs
          assignRef={this.assignContactUsRef}
          assignEmailRef={this.assignContactUsEmailRef}
        />
      </>
    );
  }
}

export default LandingPage;
