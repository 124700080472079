import "./Intro.scss";
import Container from "@material-ui/core/Container";
import private_png from "../../Assets/Images/Private 25Jun2021.png";
import cube from "../../Assets/Images/cube.png";

const Intro = (props) => {
  return (
    <div className="intro-container">
      <Container>
        <div className="intro">
          <div className="cube">
            <img src={cube} alt="Cube" />
          </div>
          <div className="text-container">
            <div className="heading">
              <span>
                Build <span className="underline">“Zero Liability”</span>{" "}
                Products
              </span>
            </div>
            <div className="secure">
              <img src={private_png} alt="Secure" />
            </div>
            <div className="text">
              <p>
                Integrate <b>Privacy</b> into your products <i>instantly</i>{" "}
                with{" "}
                <u
                  className="cursor-pointer"
                  onClick={props.executeZeroTrustScroll}
                >
                  E2EE++
                </u>{" "}
                - and get{" "}
                <u
                  className="cursor-pointer"
                  onClick={props.executeZeroTrustScroll}
                >
                  Zero-Trust++
                </u>{" "}
                <b>Security</b> for your customers easily.
              </p>
              <p>
                Secure your customers by enabling them to take full control of
                their data and pass liability using Bayun’s simple-to-integrate
                DigiLockbox™ solution.
              </p>
            </div>
            {/* <button onClick={props.executeScroll}>Request a Demo</button> */}
          </div>
        </div>
        <div className="digilock-container">
          <p>
          DigiLockbox builds upon the principles of Apple’s iCloud Keychain security model (which Apple recently expanded to almost all user data in iCloud with their <a href="https://www.apple.com/newsroom/2022/12/apple-advances-user-security-with-powerful-new-data-protections/">Advanced Data Protection</a> program, as their <a href="https://www.apple.com/newsroom/2023/12/report-2-point-6-billion-records-compromised-by-data-breaches-in-past-two-years/">studies underscore need for end-to-end encryption</a>), encrypting all data and putting users in charge of their own data. It creates a virtual secure lockbox for each user (within minutes of development effort), that is secure yet conveniently accessible from secure enclaves of trusted devices only. It manages multi-factor authorization (not authentication), that cannot be surpassed, for enforcing arbitrary access policies; and is immune to brute force attacks, phishing attacks, MITM attacks, privilege misuse and even software/system vulnerabilities. So even if the entire server infrastructure of an application were to be taken over by a malicious entity (including the authentication system), all users' data still stays safe. On the other hand, if a thief has stolen a user's password (e.g. through a phishing attack) and also gained access to the user's email account as well as SMS, even then that user's account stays protected. Conversely, we can also enable secure account recovery in case a user has forgotten their own password, in spite of the fact that the user data is kept end-to-end encrypted with no access for the server!.
          </p>
          <div className="request-button">
            <button onClick={props.executeScroll}>Request a Demo</button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Intro;
