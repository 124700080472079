import "./App.scss";
import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Footer from "./Footer/Footer";
import ProductFeatures from "./ProductFeatures/ProductFeatures";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/features">
            <ProductFeatures />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>

        <Footer />
      </Router>
    );
  }
}

export default App;
