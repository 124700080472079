import "./Whitepaper.scss";
import mailBackground from "../../Assets/Images/mail-background.png";
import mailForeground from "../../Assets/Images/mail-foreground.png";
import { useState } from "react";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Util from "../../Util/Util";
import { CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AjaxCall from "../../Util/AjaxCall";
import ReCAPTCHA from 'react-google-recaptcha';

const Whitepaper = props => {
  fontawesome.library.add(faCheckCircle);

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  const [captchaVerified, setCaptchaVerified] = useState(true);
  const [clickedCount, setClickedCount] = useState(1);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(Date.now().toString());

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ open: false, message: "" });
  };

  const handleEmailChange = e => {
    if (success) {
      setSuccess(false);
    }
    setEmail(e.target.value);
  };

  const handleVerify = () => {
    console.log("Verfied");
    setCaptchaVerified(true);
  };

  const submitEmail = async () => {
    console.log(clickedCount);
    setClickedCount(clickedCount+1);
    if (email === "") {
      setSnackbar({ open: true, message: "Email Cannot Be Empty" });
      return false;
    }
    if (!Util.isValidEmail(email)) {
      setSnackbar({ open: true, message: "Invalid Email Entered" });
      return false;
    }
    if(showRecaptcha && !captchaVerified){
      setSnackbar({ open: true, message: "Please verify captcha" });
      return false;
    }
    setLoading(true);
    await AjaxCall.submitWhitepaperEmail({ emailAddress: email });
    setLoading(false);
    setSuccess(true);
    setEmail('');
    if(clickedCount > 3){
      setShowRecaptcha(true);
      setCaptchaVerified(false);
      setCaptchaKey(Date.now().toString());
    }
  };

  return (
    <div className="whitepaper-container" ref={ref => props.assignRef(ref)}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="error"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div className="whitepaper">
        <div className="content">
          <div className="heading">
            <p>Product Whitepaper</p>
          </div>
          <div className="text">
            <p>
              Please enter your email address to receive Bayun's DigiLockbox
              whitepaper via email.
            </p>
          </div>
          <div className="email">
            <input
              type="text"
              value={email}
              placeholder="abc@xyz.com"
              onChange={handleEmailChange}
              ref={ref => props.assignEmailRef(ref)}
            />
          </div>
          <div className="whitepaper-us-captcha">
          {showRecaptcha && (
             <ReCAPTCHA sitekey="6Ld9pwAVAAAAAOl8gepucwGqsDHGFRmu35bmWZzy" key={captchaKey} onChange={handleVerify} />
          )}
          </div>
          
          <div className="button">
            {loading ? (
              <>
                <CircularProgress size={30} className="progress" />
                <button className="loading">Send Whitepaper</button>
              </>
            ) : (
              <button 
              // className={!captchaVerified ? 'disabledButton':''}
              onClick={submitEmail} 
              // disabled={!captchaVerified}
              >Send Whitepaper</button>
            )}
          </div>

          <div className="message">
            {success && (
              <>
                <div className="icon">
                  <FontAwesomeIcon icon="check-circle" />
                </div>
                <div className="text">
                  <span>
                    Your email has been successfully registered, Whitepaper will
                    be sent to you soon.
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="image-container">
          <div className="images">
            <div className="background-image">
              <img src={mailBackground} alt="Mail" />
            </div>
            <div className="foreground-image">
              <img src={mailForeground} alt="Mail" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whitepaper;
