import "./ProductFeatures.scss";
import React from "react";
import PFHeader from "./PFHeader/PFHeader";
import Features from "./Features/Features";

class ProductFeatures extends React.Component {
  render() {
    return (
      <>
        <PFHeader />
        <Features />
      </>
    );
  }
}

export default ProductFeatures;
