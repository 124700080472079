import "./Challenges.scss";
import challenges from "../../Assets/Images/challenges.png";
import Challenge from "./Challenge";

const Challenges = (props) => {
  const content = [
    {
      heading:
        "Your employees can access your customer data, creating liability for you",
      text: [
        "If <u >any</u> of your employees can access customer data, so can hackers (by exploiting vulnerabilities in software systems).",
        "You and your customers are relying on trusted software systems to enforce access controls, which can be exploited through vulnerabilities, mis-configurations, or mis-use.",
        "You have Security controls, you have Privacy controls; but data ownership is still a challenge because you can’t differentiate good guys from bad guys. So the best way to transfer ownership and full control of data to customers and become a data processor alone, is to lose unnecessary access for the good guys as well.",
      ],
    },
    {
      heading:
        "Securing your customers’ data in a perimeter-less world is difficult even with Zero-trust",
      text: [
        "Forrester’s Zero-trust model (and most implementations of it) is not truly Zero trust in the sense that it still relies on trusted software and services to do the verification of users/applications/micro-services before allowing access to data. The fact that authentication (to validate identity of the user) and authorization (to check access policy for the identified user) are separate layers still leaves big glaring holes in the overall model.",
        "Data can be anywhere and everywhere, so protecting something around data doesn’t work any more. Most security technologies of today rely on creating a secure environment around data to keep it safe, rather than protecting the data at its core. It is necessary to protect the data itself, no matter where it goes.",
      ],
    },
    {
      heading:
        "Today’s encryption model with separate Data at Rest and Data In Transit encryption is insufficient",
      text: [
        "Today’s best-in-class encryption models use separate domain-specific keys for encrypting the data. This is not good enough - even if we include Data in Use encryption. There are vulnerabilities at the boundaries of different domains. That’s why no matter what we do, breaches keep happening.",
        "Even so called end-to-end encryption, as implemented by the likes of Signal, WhatsApp, etc is also not truly end-to-end. It protects the user data from the sender’s device to the recipient’s device only, and not for the entire lifecycle of user data.",
      ],
    },
    {
      heading: "Compliance solves yesterday’s problems",
      text: [
        "Not even today’s, let alone tomorrow’s. That is why it has always been a cat and mouse chase between hackers and security products, running in circles forever. This vicious cycle needs to be ended by solving tomorrow’s problem today, and <u>forever</u>.",
      ],
    },
    {
      heading:
        "Increased security usually leads to harder deployment/management and decreased usability",
      text: [
        "If usability gets sacrificed for users and/or developers, or enterprise admins, the solution doesn’t see much adoption, or else starts getting used in sub-optimal ways for convenience, defeating the whole purpose of adding security.",
        "We need a model similar to Apple’s iCloud Keychain, which keeps a user’s credit-card numbers and passwords secure (typically the most sensitive data that any user has), and available for use in various services like Apple Pay, Website logins, App authentication, etc. It provides the best privacy and security, and yet is easy to use. Otherwise, without the desired usability, the increased security is usually not worth it.",
      ],
    },
  ];

  return (
    <div className="challenges-container">
      <div className="heading">
        <img src={challenges} alt="Challenges" />
        <p>Today's Challenges</p>
      </div>
      <div className="content">
        {content.map((challenge, index) => (
          <Challenge id={index} key={index} {...challenge} />
        ))}
      </div>
    </div>
  );
};

export default Challenges;
