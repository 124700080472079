import "./Overview.scss";
import { useState } from "react";
import overviewTitle from "../../Assets/Images/Overview.png";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Overview = (props) => {
  const [isDemoActive, setDemoActive] = useState(false);
  const [isOverviewActive, setOverviewActive] = useState(false);
  const openDemoVideo = () => {
    setDemoActive(true);
    var frame = document.getElementById("demoIframe");
    frame.src = "https://www.youtube.com/embed/WJhHm4NPiH8";
  };
  const closeDemoVideo = () => {
    setDemoActive(false);
    var frame = document.getElementById("demoIframe");
    frame.src = "";
  };
  const openOverviewVideo = () => {
    setOverviewActive(true);
    var frame = document.getElementById("overviewIframe");
    frame.src = "https://www.youtube.com/embed/vyEIEIGAhT8";
  };
  const closeOverviewVideo = () => {
    setOverviewActive(false);
    var frame = document.getElementById("overviewIframe");
    frame.src = "";
  };
  fontawesome.library.add(faPlayCircle);
  return (
    <div className="overview-container">
      <div className="overview">
        <div className="heading">
          <img src={overviewTitle} alt="Overview" />
          <p>Overview</p>
        </div>
        <div className="block-container">
          <div className="video-block" onClick={openOverviewVideo}>
            <FontAwesomeIcon icon="play-circle" className="icon" />
            <h2>Overview Presentation</h2>
            <p>
              A high-level Overview of Bayun, and why this new model is
              necessary to protect user data from never-ending breaches.
            </p>
          </div>
          <div className="video-block" onClick={openDemoVideo}>
            <FontAwesomeIcon icon="play-circle" className="icon" />
            <h2>Demos with Technical Details</h2>
            <p>
              Demos of Bayun showcasing different aspects of the SDK, and
              details on how the underlying technology works.
            </p>
          </div>
        </div>
      </div>
      <div
        // onClick={closeOverviewVideo}
        className={
          isOverviewActive
            ? "show video-container video-overview"
            : "hide video-container video-overview"
        }
      >
        <div className="video-frame">
          <iframe
            id="overviewIframe"
            width="80%"
            height="80%"
            src="https://www.youtube.com/embed/vyEIEIGAhT8"
            title="Overview Video"
            allow="fullscreen"
          ></iframe>
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={closeOverviewVideo}
            className="cross-icon"
          />
        </div>
      </div>
      <div
        // onClick={closeDemoVideo}
        className={
          isDemoActive
            ? "show video-container video-demo"
            : "hide video-container video-demo"
        }
      >
        <div className="video-frame">
          <iframe
            id="demoIframe"
            width="80%"
            height="80%"
            src="https://www.youtube.com/embed/WJhHm4NPiH8"
            title="Demo Video"
            allow="fullscreen"
          ></iframe>
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={closeDemoVideo}
            className="cross-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
