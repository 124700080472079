import "./Features.scss";
import featuresImage from "../../Assets/Images/features.png";
import Feature from "./Feature";
import { useState } from "react";
import shieldWithLock from "../../Assets/Images/shield-with-lock.png";
import firewall from "../../Assets/Images/firewall.png";
import encryption from "../../Assets/Images/encryption.png";
import universalInterfaceSign from "../../Assets/Images/universal-interface-sign.png";
import settings from "../../Assets/Images/settings.png";
import admin from "../../Assets/Images/admin.png";
import { Grid } from "@material-ui/core";

const Features = props => {
  const [features, setFeatures] = useState([
    {
      image: shieldWithLock,
      title: "Foolproof Privacy",
      shortText:
        "No reliance on trusted software for enforcing privacy controls. Privacy policies are cryptographically tied to the data, so can’t be bypassed by anyone with privileged access - be it a hacker exploiting a vulnerability or a malicious insider.",
      longText:
        "No reliance on trusted software for enforcing privacy controls. Privacy policies are cryptographically tied to the data, so can’t be bypassed by anyone with privileged access - be it a hacker exploiting a vulnerability or a malicious insider.",
      expanded: false,
    },
    {
      image: firewall,
      title: "Go Beyond ZeroTrust, Way Beyond",
      shortText:
        "ZeroTrust (or BeyondCorp) Model just says that network firewall should not be trusted, and every application or micro-segment should do its own authentication. But the model still relies on trusted authentication and authorization code for controlling data accesses to authorized users and applications. And if data is kept encrypted at rest, trusted key-vaults are used for managing encryption keys.",
      longText:
        "ZeroTrust (or BeyondCorp) Model just says that network firewall should not be trusted, and every application or micro-segment should do its own authentication. But the model still relies on trusted authentication and authorization code for controlling data accesses to authorized users and applications. And if data is kept encrypted at rest, trusted key-vaults are used for managing encryption keys. Bayun model is completely trustless where no code is trusted for enforcing access policies. Once policies have been tied to the data, these are cryptographically enforceable, even if data ends up on a server with no authentication what-so-ever or on a server running malicious or hacked code. So Bayun not only protects from hacked or malicious data, it also protects from hacked or malicious code. Note that tying of policies to data does require use of trusted code in the SDK to some extent (but SDK portion can be open-sourced, so subject to public scrutiny), however enforcement of policies doesn’t require trust in code (which is necessary even with Zero Trust model being enforced on apps or micro-segments etc)",
      expanded: false,
    },
    {
      image: encryption,
      title: "Zero Knowledge End-to-end Security",
      shortText:
        "Not just from device-to-device (e.g. like Signal/WhatsApp), or even User to User. Security that goes with the data, no matter where the data goes, and nobody can access the data without involvement from data owners. Not the service provider, application builder, or where data is stored, and not even Bayun",
      longText:
        "Not just from device-to-device (e.g. like Signal/WhatsApp), or even User to User. Security that goes with the data, no matter where the data goes, and nobody can access the data without involvement from data owners. Not the service provider, application builder, or where data is stored, and not even Bayun",
      expanded: false,
    },
    {
      image: universalInterfaceSign,
      title: "Universal Applicability",
      shortText:
        "Apple keychain generalized for all types of data, across all devices, with arbitrary fine-grained sharing and access policies including provision for enterprise controls. Not limited to iPhones. Not limited to single-user accessing their own data with no sharing. ",
      longText:
        "Apple keychain generalized for all types of data, across all devices, with arbitrary fine-grained sharing and access policies including provision for enterprise controls. Not limited to iPhones. Not limited to single-user accessing their own data with no sharing. ",
      expanded: false,
    },
    {
      image: settings,
      title: "Enterprise Controls",
      shortText:
        "Backdoor for enterprise security admins to transfer an ex-employee’s data to another employee without compromising security. Even enterprise admin with privileged access can’t become a malicious insider or be fooled to get access to employee data. Control encryption parameters from a central dashboard.",
      longText:
        "Backdoor for enterprise security admins to transfer an ex-employee’s data to another employee without compromising security. Even enterprise admin with privileged access can’t become a malicious insider or be fooled to get access to employee data. Control encryption parameters from a central dashboard.",
      expanded: false,
    },
    {
      image: admin,
      title: "Automatically Created Admin-panel",
      shortText:
        "With full Visibility and Control for data owners, whether be it a consumer, employee, or company. No work from the developer. Whitelabel and include in your own app, or allow reuse as-is.",
      longText:
        "With full Visibility and Control for data owners, whether be it a consumer, employee, or company. No work from the developer. Whitelabel and include in your own app, or allow reuse as-is.",
      expanded: false,
    },
  ]);

  const expandCollapseHandler = (action, index) => {
    const featuresArr = [...features];
    const feature = featuresArr[index];
    if (action === "expand") {
      feature.expanded = true;
    } else {
      feature.expanded = false;
    }
    featuresArr[index] = feature;
    setFeatures(featuresArr);
  };

  return (
    <div className="features-container">
      <div className="features">
        <div className="heading">
          <img src={featuresImage} alt="Features" />
          <p>Product Features</p>
        </div>
        <div className="content">
          <Grid container spacing={10}>
            {features.map((feature, index) => (
              <Feature
                handleExpandCollapse={expandCollapseHandler}
                key={index}
                id={index}
                {...feature}
              />
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Features;
