const Value = props => {
  return (
    <div className="value">
      <div className="value-heading">
        <div className="border"></div>
        <div className="value-heading-text">{props.heading}</div>
      </div>
      <div className="value-text">
        <ul>
          {props.text.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Value;
